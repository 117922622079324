<template>
  <div>
    <districts-sub-classifier></districts-sub-classifier>
  </div>
</template>

<script>
import DistrictsSubClassifier from "@/components/admin/subclassifiers/districtsSubClassifier";
export default {
  name: "districtsSubClassifierView",
  components: { DistrictsSubClassifier },
};
</script>

<style scoped></style>
