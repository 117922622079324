<template>
  <div>
    <h2>Участки</h2>

    <el-table stripe border :data="districtsList.content" style="width: 100%">
      <el-table-column prop="districtName" label="Наименование">
      </el-table-column>
      <el-table-column width="450" label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="openEditModal(raw.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteItem(raw.row)"
                style="float: right; margin-right: 10px"
                type="danger"
                >Удалить</el-button
              >
              <el-button
                size="mini"
                @click="viewItem(raw.row)"
                style="float: right"
                type="primary"
                >Просмотреть</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="districtsList.size"
      :current-page.sync="districtsList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="districtsList.totalElements"
    >
    </el-pagination>

    <el-dialog
      destroy-on-close
      title="Классификатор отходов"
      :visible.sync="modalShow"
    >
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input v-model="district.districtName"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="save" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "districtsSubClassifier",
  mixins: [notification],
  data() {
    return {
      searchPole: "",
      pageOptions: {
        size: 20,
        page: 0,
      },
      district: {
        areaId: null,
        districtId: null,
        districtName: "",
      },
      modalShow: false,
    };
  },

  computed: {
    ...mapGetters({
      districtsList: "GETLISTDISTRICS",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.districtsList.number,
          size: this.districtsList.size,
        };
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.getDistrictsList({
        page: 0,
        size: val,
        areaId: this.$route.params.areaId,
      });
    },

    handleCurrentChange(val) {
      this.getDistrictsList({
        page: val - 1,
        size: this.districtsList.size,
        areaId: this.$route.params.areaId,
      });
    },

    openEditModal(item) {
      console.log(item);
      this.district = item;
      this.modalShow = true;
    },

    deleteItem(item) {
      this.confirm(
        "Удалить район",
        "Вы уверены что хотите удалить район",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store.dispatch("deleteDistrict", item).then((response) => {
          if (response.data === "DELETED") {
            this.notification("Успешно", "Район удалена", "success");
            this.getDistrictsList({
              ...this.pageOptions,
              ...{ areaId: this.$route.params.areaId },
            });
          }
        });
      });
    },

    viewItem(item) {
      this.$router.push("districts/" + item.districtId);
      console.log(item);
    },

    search() {},

    add() {
      this.district = {
        areaId: "",
        districtId: null,
        districtName: "",
      };
      this.modalShow = true;
    },

    save() {
      this.district.areaId = this.$route.params.areaId;
      if (this.district.districtId === null) {
        this.$store
          .dispatch("createDistrict", this.district)
          .then((response) => {
            if (response.data.districtId !== undefined) {
              this.notification("Успешно", "Район добавлена", "success");
              this.getDistrictsList({
                ...this.pageOptions,
                ...{ areaId: this.$route.params.areaId },
              });
              this.modalShow = false;
            }
          });
      } else {
        this.$store
          .dispatch("updateDistrict", this.district)
          .then((response) => {
            if (response.data.districtId !== undefined) {
              this.notification("Успешно", "Район обновлена", "success");
              this.getDistrictsList({
                ...this.pageOptions,
                ...{ areaId: this.$route.params.areaId },
              });
              this.modalShow = false;
            }
          });
      }
    },

    getDistrictsList(data) {
      this.$store.dispatch("listAllDistrictsByAreaId", data);
    },
  },

  mounted() {
    this.getDistrictsList({
      ...this.pageOptions,
      ...{ areaId: this.$route.params.areaId },
    });
  },
};
</script>

<style scoped></style>
